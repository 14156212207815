import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersFirmwareEngineer = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <p className="jobdescription col-md-12">
        {" "}
        An orchestra without a conductor is a terrible thing to listen to. Help shape what Uno can be and guide our team to make our products a reality in the best possible way. 
      </p>

      <h1 className="jobtitle col-md-12">Product Manager</h1>
      <div className="jobdescription col-md-12"> </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Bridge the gap between hardware and software to create a cohesive
              user experience.
            </li>
            <li>
              Own the day-to-day backlogs for the software and firmware that
              powers Uno Cup and Uno Port.
            </li>
            <li>
              Oversee physical and digital teams and guide them to bring Uno
              products to life through groundbreaking technology and seamless
              integration.
            </li>
            <li>
              Work directly with leadership to establish product roadmaps and
              execute them.
            </li>
            <li>
              Ensure the quality of both physical and digital products is
              consumer-grade.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              A natural leader who can bring people together around a common
              goal.
            </li>
            <li>Intuitively user experience-driven.</li>
            <li>A perfectionist grounded in pragmatism.</li>
            <li>Autonomous in your day-to-day and know when to seek help.</li>
            <li>Painfully attentive to details.</li>
            <li>An excellent communicator in-person and on paper.</li>
            <li>Respectful to your and other people’s time. </li>
            <li>Able to synthesize isolated parts into a cohesive product.</li>
            <li>A self-starter who is comfortable wearing many hats to get things done.</li>
            <li>Organized in your thinking, process and management.</li>
            <li>Excellent at executing a plan and at adapting to changes. </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              3+ years experience as a Product Manager at the intersection of
              hardware and software, ideally with hardware, embedded/firmware or
              electrical engineering experience.
            </li>
            <li>
              Technical degree from an accredited university or strong hands-on
              technical experience.
            </li>
            <li>Experience managing teams of 15+ people.</li>
            <li>
              Deep understanding of both software development and manufacturing
              pipeline.
            </li>
            <li>
              Ability to accurately break down, scope and forecast large
              projects—from top level to the smallest tasks.
            </li>
            <li>Extensive experience managing complex projects.</li>
            <li>
              Track record owning a product roadmap and seeing it through.
            </li>
            <li>
              Knowledge of compliance and hardware certifications (FCC, UL,
              etc).
            </li>
            <li>
              Experience in both a startup and larger corporate environment a
              plus.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersFirmwareEngineer;
